import { useLayoutEffect, useState } from 'react';

import { InjectedFieldProps, PublicFieldProps } from 'react-admin';
import { DateTime } from 'luxon';
import { BumpResourceSheetDto } from '@vatos-pas/common';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { convertTo12HourFormat } from 'modules/master-sheet/utils/convertTo12HourFormat';
import { Show } from 'components/Show';
import {
  BumpoutTimesheetFields,
  BumpoutTimesheetFieldType,
} from '../views/bumpout-timesheet-list';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_LOG_HOURS_BUMPOUT } from 'providers/permissions';

type TimeInputProps = {
  value: BumpoutTimesheetFieldType;
  label: string;
  fields: BumpoutTimesheetFields | null;
  onChangeField: (
    id: string,
    name: BumpoutTimesheetFieldType,
    value: DateTime | string | null,
  ) => void;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const TimeInput = ({
  value,
  record,
  fields,
  onChangeField,
}: TimeInputProps) => {
  if (!record?.id) return null;

  const { hasPermission } = PermissionsProvider.useContainer();

  const [isInputOpen, setIsInputOpen] = useState(false);

  const canInputHours = hasPermission(CAN_LOG_HOURS_BUMPOUT);
  const isNotStarted = record?.bumperApprovalStatus === 'NOT_STARTED';
  const isPendingSupervisorAction =
    record?.bumperApprovalStatus === 'PENDING_APPROVAL' ||
    record?.bumperApprovalStatus === 'PENDING_REVIEW';
  const fieldValue = (fields?.[record.id]?.[value] as DateTime)?.toFormat(
    'HH:mm',
  );

  const classes = useStyles({ isInputOpen, fieldValue: !!fieldValue });

  // Bumpers can update the log hours until
  // Supervisor takes an action
  useLayoutEffect(() => {
    if (
      (isNotStarted || isPendingSupervisorAction) &&
      canInputHours &&
      record?.[value]
    ) {
      onChangeField(
        record?.id as string,
        value,
        DateTime.fromISO(record?.[value] as string),
      );
    }
  }, []);

  return (
    <Show
      condition={(isNotStarted || isPendingSupervisorAction) && canInputHours}
      fallback={
        <Box whiteSpace="nowrap">
          {record?.[value] ? convertTo12HourFormat(String(record[value])) : ''}
        </Box>
      }
    >
      <Show
        condition={isInputOpen || !isNotStarted}
        fallback={
          <Button
            style={{
              whiteSpace: 'nowrap',
            }}
            onClick={() => setIsInputOpen(true)}
            color="primary"
          >
            Log hours
          </Button>
        }
      >
        <input
          className={classes.input}
          type="time"
          value={fieldValue ?? null}
          onChange={event =>
            onChangeField(
              record?.id as string,
              value,
              event.target.value ? DateTime.fromISO(event.target.value) : null,
            )
          }
        />
      </Show>
    </Show>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    inset: 0,
    padding: theme.spacing(1),
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
  },
  input: {
    padding: theme.spacing(0.5),
    fontSize: theme.typography.fontSize,
    width: '100%',
    minWidth: 80,
  },
}));

export default TimeInput;
