import { useDataProvider, useListContext } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';

import { downloadFile } from 'utils';

const AccountingExportButton = props => {
  const dataProvider = useDataProvider();
  const { filterValues } = useListContext();

  const isDisabled =
    !filterValues?.['dateApproved||$lte'] &&
    !filterValues?.['dateApproved||$gte'];

  const getAccountingExportReport = async () => {
    const response = await dataProvider.getOne<{
      id: string;
      blob: Blob;
    }>('prod-pay-master-sheet-quickbooks', {
      // @ts-expect-error `filter` is not typed
      filter: filterValues,
    });

    const blobURL = URL.createObjectURL(response.data.blob);
    downloadFile(blobURL, `accounting-report-${response.data.id}.xlsx`);
    URL.revokeObjectURL(blobURL);
  };

  return (
    <Button
      color="primary"
      startIcon={<DownloadIcon />}
      onClick={async () => getAccountingExportReport()}
      disabled={isDisabled}
      {...props}
    >
      Accounting Export
    </Button>
  );
};

export default AccountingExportButton;
