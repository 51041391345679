import {
  DataProvider,
  fetchUtils,
  GetListParams,
  GetOneParams,
} from 'react-admin';
import { axiosInstance } from 'services/http';
import {
  CondOperator,
  QueryFilter,
  QueryJoin,
  QuerySort,
  RequestQueryBuilder,
} from '@nestjsx/crud-request';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
// TODO This is a uuid hack to filter works based on a uuid
import { validate as uuidValidate } from 'uuid';
import { POFilterStatus } from 'modules/jobs/views/filters/job-sheet-filters';
import { OrderDirection } from 'components/OrderButton';

const httpClient = fetchUtils.fetchJson;

const parseNumberInput = value => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');

    const parsedValue = parseFloat(value);

    return isNaN(parsedValue) ? null : parseFloat(parsedValue.toFixed(2));
  }
  return value;
};

const boardCostFields = [
  'repairsRate',
  'garageCostPerBoard',
  'hangingCostPerBoard',
  'finishingCostPerBoard',
  'sprayingCostPerBoard',
];

const getMessage = (error: any) => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.message &&
    typeof error.response.data.message === 'string'
  ) {
    return error.response.data.message;
  } else {
    return (
      (error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message[0]) ||
      (error.response && error.response.statusText) ||
      error.message
    );
  }
};

const composeQuery = (
  paramsFilter: any,
  additionalFilters?: QueryFilter[],
): RequestQueryBuilder => {
  const queryBuilder = RequestQueryBuilder.create();

  if (
    !paramsFilter ||
    paramsFilter === '' ||
    (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')
  ) {
    paramsFilter = {};
  }

  if (paramsFilter.s) {
    return queryBuilder.search(paramsFilter.s);
  }

  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  const queryFilters: QueryFilter[] = [];

  for (const key of Object.keys(flatFilter)) {
    // inject time zone onto date ranges (date input as filter is broken)
    if (key === 'dateApproved||$gte') {
      flatFilter[key] = new Date(`${flatFilter[key]} 00:00:00`).toISOString();
    }
    if (key === 'dateApproved||$lte') {
      flatFilter[key] = new Date(`${flatFilter[key]} 23:59:59`).toISOString();
    }
    if (key === 'datePaid||$gte') {
      flatFilter[key] = new Date(`${flatFilter[key]} 00:00:00`).toISOString();
    }
    if (key === 'datePaid||$lte') {
      flatFilter[key] = new Date(`${flatFilter[key]} 23:59:59`).toISOString();
    }
    if (key === 'repairDate||$gte') {
      flatFilter[key] = new Date(`${flatFilter[key]} 00:00:00`).toISOString();
    }
    if (key === 'repairDate||$lte') {
      flatFilter[key] = new Date(`${flatFilter[key]} 23:59:59`).toISOString();
    }

    if (
      key === 'currentPhaseDateRequested||$eq' ||
      key === 'jobPhaseDateRequested||$eq'
    ) {
      const overrideZone = DateTime.fromISO(`${flatFilter[key]}T00:00:00`, {
        zone: 'America/New_York',
      });
      flatFilter[key] = overrideZone.toString();
    }

    const splitKey = key.split('||');

    let field = splitKey[0];

    if (field === '$join') {
      queryBuilder.setJoin(flatFilter[key]);
      continue;
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }

    let ops = splitKey[1];

    if (!ops) {
      if (
        typeof flatFilter[key] === 'number' ||
        typeof flatFilter[key] === 'boolean' ||
        (flatFilter[key].match && flatFilter[key].match(/^\d+$/)) ||
        uuidValidate(flatFilter[key])
      ) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    queryFilters.push({
      field,
      operator: ops,
      value: flatFilter[key],
    } as QueryFilter);
  }

  if (additionalFilters) {
    queryFilters.push(...additionalFilters);
  }

  queryBuilder.setFilter(queryFilters);

  return queryBuilder;
};

const overrideExactDateFilter = (filters: any, field: string) => {
  if (!filters[field]) return;

  const date = filters[field];
  delete filters[field];
  const name = field.split('||')[0];
  filters[`${name}||$gte`] = date + 'T00:00:00Z';
  filters[`${name}||$lte`] = date + 'T23:59:59Z';
};

const OverridableResources = {
  users: 'user',
  'board-pre-fill': 'model-board-sheet',
};

export const dataProvider: DataProvider = {
  getList: (resource, params: GetListParams & { join?: QueryJoin[] }) =>
    new Promise((resolve, reject) => {
      const overriddenResource = OverridableResources?.[resource];
      const newResource = overriddenResource ?? resource;
      const { page, perPage } = params.pagination;

      if (resource === 'job-warranty-sheet') {
        if (params.filter && params.filter['activeJob||$eq']) {
          delete params.filter['activeJob||$eq'];
        }
        if (params.sort.field === 'sprayPhaseDateStart') {
          params.sort.field = 'bump1PhaseDateStart';
        }
      }

      if (resource === 'job-sheet') {
        if (params.filter && params.filter['poStatus||$eq']) {
          if (params.filter['poStatus||$eq'] === POFilterStatus.UnSigned) {
            delete params.filter['poStatus||$eq'];
            params.filter['poStatus||$ne'] = POFilterStatus.Signed;
          }
        }
      }

      if (resource === 'job-master-sheet') {
        if (params.filter) {
          const filters = Object.entries(params.filter);

          const search = filters.reduce((acc, [keyWithOperator, value]) => {
            const [key, operator] = keyWithOperator.split('||');

            if (key === 'contractorId') {
              acc.$or = ['finishPhase', 'hangPhase', 'sprayPhase'].map(
                phase => ({ [`${phase}ContractorId`]: { $eq: value } }),
              );
            } else {
              acc[key] = { [operator]: value };
            }

            return acc;
          }, {} as Record<string, { [x: string]: unknown } | { [x: string]: unknown }[]>);

          params.filter = {
            s: search,
          };
        }
      }

      if (resource === 'material-delivery-sheet') {
        if (params.filter) {
          overrideExactDateFilter(params.filter, 'dateExpected||$eq');
          overrideExactDateFilter(params.filter, 'datePromised||$eq');
          overrideExactDateFilter(params.filter, 'dateDelivery||$eq');
        }
        if (!params.filter['noPromissedDate||$eq']) {
          delete params.filter['noPromissedDate||$eq'];
        }
        if (!params.filter['noDeliveryDate||$eq']) {
          delete params.filter['noDeliveryDate||$eq'];
        }
        if (!params.filter['late||$eq']) {
          delete params.filter['late||$eq'];
        }
      }

      const queryBuilder = composeQuery(params.filter)
        .setLimit(perPage)
        .setPage(page)
        .sortBy(params.sort as QuerySort)
        .setOffset((page - 1) * perPage);

      const query = queryBuilder.query();
      const url = `${newResource}?${query}`;

      return axiosInstance
        .get(url)
        .then(response => {
          if (resource === 'job-master-sheet') {
            response.data.data = response.data.data.map((item: any) => ({
              ...item,
              id: item.jobId,
            }));
          }
          if (resource === 'job-order') {
            response.data.data = response.data.data.map((item: any) => ({
              ...item,
              id: item.spOrderId,
            }));
          }
          if (resource === 'pre-rock-sheet') {
            response.data.data = response.data.data.map((item: any) => ({
              ...item,
              id: item.jobId,
            }));
          }
          if (resource === 'job-order-board') {
            response.data.data = response.data.data.map((item: any) => ({
              ...item,
              id: item.jobId,
            }));
          }
          if (resource === 'job-order-item') {
            response.data.data = response.data.data.map((item: any) => ({
              ...item,
              id: item.jobId,
            }));
          }
          if (resource === 'contractor-user/batch-process') {
            response.data.data = response.data.data.map((item: any) => ({
              ...item,
              // react-admin validation
              id: uuidv4(),
            }));
          }
          return resolve({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    }),
  getOne: (
    resource,
    params: GetOneParams & {
      join?: QueryJoin[];
      filter?: { [key: string]: string };
    },
  ) => {
    const queryBuilder = composeQuery(params.filter);

    if (resource === 'route') {
      params.join = [
        {
          field: 'subdivisions',
        },
      ];
    }

    queryBuilder.setJoin(params.join as QueryJoin[]);

    const query = queryBuilder.query();

    if (resource === 'prod-pay-master-sheet-quickbooks') {
      return axiosInstance
        .get(`/${resource}?${query}`, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        })
        .then(response => {
          // Convert ArrayBuffer response to Blob
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          return {
            data: {
              id: uuidv4(),
              blob,
            },
          };
        });
    }

    return axiosInstance
      .get(`/${resource}/${params.id}?${query}`)
      .then(response => {
        if (
          response.data.userRoles ||
          response.data.contractorRegions ||
          response.data.userRegions ||
          response.data.supplierRegions
        ) {
          delete response.data.contractorRegions;
          delete response.data.userRoles;
          delete response.data.userRegions;
          delete response.data.supplierRegions;
        }
        return {
          data: response.data,
        };
      });
  },
  getMany: (resource, params) =>
    new Promise((resolve, reject) => {
      const queryBuilder = RequestQueryBuilder.create().setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${params.ids}`,
      });

      const url = `${resource}?${queryBuilder.query()}`;

      return axiosInstance
        .get(url)
        .then(response =>
          resolve({
            data: response.data.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    }),
  getManyReference: (resource, params) => {
    return new Promise((resolve, reject) => {
      if (
        (resource === 'user-role' ||
          resource === 'user-region' ||
          resource === 'contractor-region' ||
          resource === 'supplier-region') &&
        !params.id
      ) {
        return resolve({
          data: [],
          total: 0,
        });
      }
      const { page, perPage } = params.pagination;

      const additionalFilters: QueryFilter[] = [];

      if (params.id && params.target) {
        additionalFilters.push({
          field: params.target,
          operator: CondOperator.EQUALS,
          value: params.id,
        });
      }

      const queryBuilder = composeQuery(params.filter, additionalFilters);

      queryBuilder
        .sortBy(params.sort as QuerySort)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage);

      const url = `${resource}?${queryBuilder.query()}`;
      return axiosInstance
        .get(url)
        .then(({ data }) =>
          resolve({
            data: data.data,
            total: 15,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    });
  },
  update: (resource, params) => {
    const overriddenResource = OverridableResources?.[resource];
    const newResource = overriddenResource ?? resource;
    const boardCostsNoRepairRate = boardCostFields.slice(1);

    if (resource === 'region' || resource === 'subdivision') {
      // Parse each board cost field to ensure it is a valid number
      boardCostFields.forEach(field => {
        params.data[field] = parseNumberInput(params.data[field]);
      });
    }

    if (resource === 'route') {
      delete params.data?.supervisorUser;
    }

    if (resource === 'subdivision') {
      const newRouteId = params.data?.routeId;
      delete params.data?.route;

      params.data.route = {
        id: newRouteId,
      };

      // Nullify costs if override is not set in subdivision
      if (!params.data.overrideRegionCostPerBoard) {
        // Skip repairsRate
        boardCostsNoRepairRate.forEach(field => {
          params.data[field] = null;
        });
      }
    }

    return new Promise((resolve, reject) =>
      axiosInstance
        .patch(`${newResource}/${params.id}`, params.data)
        .then(response =>
          resolve({
            data: response.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    );
  },
  put: (resource, params) => {
    const overriddenResource = OverridableResources?.[resource];
    const newResource = overriddenResource ?? resource;

    return new Promise((resolve, reject) =>
      axiosInstance
        .put(`${newResource}/${params.id}`, params.data)
        .then(response =>
          resolve({
            data: response.data,
          }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    );
  },
  updateMany: (resource, params) => {
    const overriddenResource = OverridableResources?.[resource];
    const newResource = overriddenResource ?? resource;

    return httpClient(`${newResource}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  create: (resource, params) => {
    const overriddenResource = OverridableResources?.[resource];
    const newResource = overriddenResource ?? resource;
    const boardCostsNoRepairRate = boardCostFields.slice(1);

    if (resource === 'region' || resource === 'subdivision') {
      // Set any non-numeric board cost fields to null
      boardCostFields.forEach(field => {
        if (typeof params.data[field] !== 'number') {
          params.data[field] = null;
        }
      });
    }

    if (resource === 'subdivision' && !params.data.overrideRegionCostPerBoard) {
      boardCostsNoRepairRate.forEach(field => {
        params.data[field] = null;
      });
    }

    return new Promise((resolve, reject) =>
      axiosInstance
        .post(`${newResource}`, params.data)
        .then(response => {
          if (newResource === 'board-excel-floor') {
            response.data = {
              ...response.data,
              id: uuidv4(),
            };
          }

          if (newResource === 'contractor-user/batch-process') {
            response.data = {
              ...response.data,
              // react-admin validation
              id: uuidv4(),
            };
          }

          if (newResource === 'repair-payment/batch-process') {
            response.data = {
              ...response.data,
              // react-admin validation
              id: uuidv4(),
            };
          }

          return resolve({
            data: response.data,
          });
        })
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    );
  },
  delete: (resource, params) =>
    new Promise((resolve, reject) =>
      axiosInstance
        .delete(`${resource}/${params.id}`)
        .then(({ data }) => resolve({ data }))
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        }),
    ),
  deleteMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      return Promise.all(
        params.ids.map(id => axiosInstance.delete(`${resource}/${id}`)),
      )
        .then(responses =>
          resolve({ data: responses.map(response => response.data) }),
        )
        .catch(error => {
          const message = getMessage(error);

          error.message = message;
          return reject(error);
        });
    });
  },
  move: (
    resource: string,
    params: { id: number | string; direction: OrderDirection },
  ) =>
    new Promise((resolve, reject) =>
      axiosInstance
        .patch(
          `${resource}/${params.id}/move`,
          JSON.stringify({ direction: params.direction }),
        )
        .then(response => resolve({ data: response }))
        .catch(error => {
          const message = getMessage(error);
          error.message = message;
          return reject(error);
        }),
    ),
};

export default dataProvider;
